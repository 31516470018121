<template>
  <div>
    <load-profile></load-profile>
    <bet-setting-menu></bet-setting-menu>

    <!-- Table Container Card -->
    <b-card>
      <h3>{{ username }}</h3>
      <validation-observer ref="updateForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          key="updateForm"
          @submit="submit"
          :form="$refs.updateForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data" :initValue="initData">
            <template #lotteryId="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-single-select
                    :reduce="true"
                    v-model="data.lotteryId"
                    :selection-label="$i18n.locale == 'en' ? 'name' : 'nameKh'"
                    :options="lotteries"
                    :clearable="false"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  ></n-single-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
            <template #lotteryTimeId="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-single-select
                    :reduce="true"
                    v-model="data.lotteryTimeId"
                    :options="lotteryTimes"
                    :clearable="false"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  ></n-single-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
            <template #post="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-single-select
                    :reduce="true"
                    v-model="data.post"
                    selection-key="post"
                    selection-label="post"
                    :options="channels"
                    :clearable="true"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  ></n-single-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                :loading="loading"
                v-if="$can('create', resource)"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { BFormGroup, BRow, BCol, BCard } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NSingleSelect from "@/components/NSingleSelect";
import BetSettingMenu from "@/views/player/BetSettingMenu.vue";
import moment from "moment";

const BlockNumberRepository = Repository.get("memberBlockNumber");
const LotteryRepository = Repository.get("lottery");
const PlayerRepository = Repository.get("player");

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BCard,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NSingleSelect,
    BetSettingMenu,
  },
  directives: {
    Ripple,
  },
  watch: {
    "data.lotteryId"(value) {
      if (value) {
        this.data.lotteryTimeId = null;
        this.data.post = "";
        this.getLotteryTime(value);
      }
    },
    "data.lotteryTimeId"(value) {
      if (value) {
        this.data.post = "";
        this.getLotteryChannel(value);
      }
    },
  },
  data() {
    return {
      username: "",
      lotteries: [],
      lotteryTimes: [],
      channels: [],
      data: {
        lotteryId: null,
        lotteryTimeId: null,
        post: "",
        date: moment().format("YYYY-MM-DD"),
      },
      initData: {},
      loading: false,
    };
  },
  mounted() {
    this.showPlayer();
    this.getLottery();
  },
  methods: {
    showPlayer() {
      PlayerRepository.show(this.$route.params.id).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.username = data.username;
        }
      });
    },
    getLottery() {
      LotteryRepository.listActive().then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.lotteries = [...data];
          if (this.lotteries.length) {
            this.data.lotteryId = this.lotteries[0].id;
          }
          this.show();
        }
      });
    },
    getLotteryTime(lottery) {
      const index = this.lotteries.findIndex((element) => {
        return element.id == lottery;
      });

      if (index != -1) {
        let times = this.lotteries[index].times;
        times = times.map((element) => {
          return {
            ...element,
            name: moment(element.name, "HH:mm")
              .locale(this.$i18n.locale)
              .format("h:mm a"),
          };
        });
        this.lotteryTimes = [...times];

        if (!this.data.lotteryTimes && this.lotteryTimes.length) {
          this.data.lotteryTimeId = this.lotteryTimes[0].id;
        }
      }
    },
    getLotteryChannel(time) {
      const index = this.lotteryTimes.findIndex((element) => {
        return element.id == time;
      });

      if (index != -1) {
        this.channels = [...this.lotteryTimes[index].posts];
      }
    },
    show() {
      this.loading = true;
      BlockNumberRepository.show(this.$route.params.blockId)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.initData = {
              ...data,
            };
          }
        })
        .catch((error) => {})
        .then(() => {
          this.loading = false;
        });
    },
    submit() {
      this.$refs.updateForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          BlockNumberRepository.update(this.$route.params.blockId, {
            ...this.data,
            userId: this.$route.params.id,
          })
            .then((response) => {
              this.back();
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.updateForm.setErrors(error.response?.data?.errors);
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
    back() {
      this.$router.push({
        name: "list-player-block-number",
        params: this.$route.params.id,
      });
    },
  },
  setup() {
    const fields = [...FormInput];
    const resource = "player";

    return { fields, resource };
  },
};
</script>